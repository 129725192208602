import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15')
];

export const server_loads = [3];

export const dictionary = {
		"/": [4],
		"/assign_family": [~11],
		"/legal/privacy": [12],
		"/legal/tos": [13],
		"/login": [14],
		"/(app)/recipe": [~5,[2]],
		"/(app)/recipe/add": [8,[2]],
		"/(app)/recipe/[id]": [~6,[2,3]],
		"/(app)/recipe/[id]/edit": [7,[2,3]],
		"/(app)/settings": [~9,[2]],
		"/share/[id]": [~15],
		"/(app)/tools": [10,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';